import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/shows',
    meta: { middlewares: [Auth, HasFeature('booking')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ShowList',
        component: () => import('./views/ShowList.vue'),
        meta: { title: 'Shows' },
      },
      {
        path: ':show',
        component: () => import('./layouts/ShowLayout.vue'),
        children: [
          {
            path: '',
            name: 'ShowGeneralView',
            component: () => import('./views/ShowGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'details',
            name: 'ShowDetailsView',
            component: () => import('./views/ShowDetailsView.vue'),
            meta: { title: ':resource: Details' },
          },
          {
            path: 'production',
            name: 'ShowProductionView',
            component: () => import('./views/ShowProductionView.vue'),
            meta: {
              title: ':resource: Production',
              middlewares: [HasFeature('booking_production')],
            },
          },
          {
            path: 'tickets',
            name: 'ShowTicketsView',
            component: () => import('./views/ShowTicketsView.vue'),
            meta: { title: ':resource: Tickets' },
          },
          {
            path: 'memodeal',
            name: 'ShowMemodealView',
            component: () => import('./views/ShowMemodealView.vue'),
            meta: {
              title: ':resource: Memodeal',
              middlewares: [HasFeature('booking_memodeal')],
            },
          },
          {
            path: 'accreditations',
            name: 'ShowAccreditationsView',
            component: () => import('./views/ShowAccreditationsView.vue'),
            meta: {
              title: ':resource: Accreditations',
              middlewares: [HasFeature('booking_accreditation')],
            },
          },
          {
            path: 'contacts',
            name: 'ShowContactsView',
            component: () => import('./views/ShowContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'media',
            name: 'ShowMediaView',
            component: () => import('./views/ShowMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'ShowLogsView',
            component: () => import('./views/ShowLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
